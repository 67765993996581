<template >
    <div class="content">
        <div class="top_view">
            <div class="inviteCount">
                <div class="invite_data">
                    <div>成功邀请</div>
                    <div style="margin-top:5px;">
                        <div style="font-size: 24px;">{{this.myInvite.inviterCode}}</div>
                        <div>人</div>
                    </div>
                </div>
                <div class="invite_data">
                    <div>专享次数</div>
                    <div style="margin-top:5px;">
                        <div style="font-size: 24px;">{{this.myInvite.inviterCode}}</div>
                        <div>次</div>
                    </div>
                </div>
            </div>
            <div class="invite_heard">
                <div class="invite_title">邀请统计</div>
            </div>
        </div>

        <div class="top_view">
            <div class="inviteList">
                <div class="invite_list_data">
                    <div class="invite_list_data_title">
                        <div>受邀人</div>
                        <div>受邀时间</div>
                    </div>
                    <div>
                        <div class="invite_list_data_li"
                        v-for="(invite, index) in this.myInvite.listMirb" 
                        :key="index">
                            <div>{{invite.phone}}</div>
                            <div>{{invite.inviterTime}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="invite_heard">
                <div class="invite_title">邀请记录</div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { List } from 'vant';

export default {
    components: {},
    data() {
        return {
            memberId: this.$route.query.memberId,
            myInvite:{
                inviterCode:0,
                listMirb:[],
                sharedCode:0
            }
        };
    },
    methods: {
        loadInviteList(){
            let params = {
                inviterId: this.memberId,
            };
            this.$axios({
                url: this.$requests.member.getInvitationPolite,
                method: "post",
                data: params,
            }).then((res) => {
                let wrapper = res.data || {};
                this.myInvite = wrapper.data || [];
            }).catch((e) => { });
        }
    },
    mounted() { 
        // 获取我的邀请记录
        this.loadInviteList();
    },
};
</script>
  
<style lang="less" scoped>
.content {
    background: #FFCFBD;
    width: 100%;
    min-height: 100%;
    padding: 5vw;
}

.top_view{
    display: flex;
    position: relative;
    margin-top: 30px;
}

.inviteCount {
    background: rgba(238, 56, 48, 0.1);
    border-radius: 7px;
    width: 90vw;
    height: calc(90vw/66*26);
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.invite_data{
    background: rgba(255,255,255,0.8);
    box-shadow: 0px 1px 4px 0px rgba(111,8,3,0.2);
    border-radius: 6px;
    width: 45%;
    margin-top: 25px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div{
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #EE3830;
        display: flex;
        align-items: baseline;
    }
}

.inviteList{
    background: rgba(238, 56, 48, 0.1);
    border-radius: 7px;
    width: 90vw;
    margin-top: 15px;
    display: flex; 
    align-items: center;
    justify-content: space-evenly;
}

.invite_list_data{
    width: 94%;
    background: rgba(255,255,255,0.8);
    box-shadow: 0px 1px 4px 0px rgb(111 8 3 / 20%);
    border-radius: 6px;
    margin-top: 25px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    min-height: calc(90vw/66*32);
}

.invite_list_data_title{
    display: flex;
    height: 30px;
    align-items: center;
    div{
        width: 50%;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #EE3830;
    }
}

.invite_list_data_li{
    display: flex;
    div{
        width: 50%;
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        text-align: center;
    }
}

.invite_heard{
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.invite_title{
    width: 120px;
    height: 30px;
    background: #EE3830;
    color: white;
    box-shadow: 0px 2px 3px 0px rgb(111 8 3 / 60%);
    font-size: 18px;
    font-weight: 500;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>